import {useRef, useEffect} from "react";
import { useTranslation } from 'react-i18next';
import {FaBars, FaTimes } from "react-icons/fa";
import React from 'react';
import "../styles/main.css";
import seaport from '../assets/images/seaport.jpg';
import sunset from '../assets/images/sunsetport.jpg';
import energy from '../assets/images/energyproduction.jpg';
import i18next from 'i18next';
import { Zoom, Fade } from "react-scroll-motion";
import Maps from './Maps';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import LazyLoad from 'react-lazy-load';
import {lazy, Suspense} from 'react';

//const Translations = lazy(() => import(`../locales/${i18next.language}/translations.json`)); 

function Home(){
    const { t, i18 } = useTranslation();
    const animation = useAnimation();
    const [ref, inView, entry] = useInView({
    
    triggerOnce: false,
    });
    const homeVariant = {
        hidden: { opacity: 0, scale: 0.9 },
        visible: { opacity: 1, scale: 1, transition: { duration: 0.7 } },
      };
    useEffect(() => {
        if (inView) {
          animation.start("visible");
        } else {
            animation.start("hidden");
        }
      }, [animation, inView]);
    function handleClick(lang) {
        i18next.changeLanguage(lang);
    }
    
    window.scrollTo(0, 0);
    return (
        <motion.div
            id="home"
            className="aboutContainer"
            ref={ref}
            initial="hidden"
            animate={animation}
            variants={homeVariant}
            >
                <div id="home" className="HomeimageContainer">
                    <LazyLoad>
                        <div className="imageTextContainer" >
                            <img className="HomeImage" loading="lazy" src={seaport} alt="SloganImage" /> 
                            <p className="homeoverlay-text one">{t('Home.1')}</p>
                        </div>
                    </LazyLoad>

                    <LazyLoad>
                    <div className="imageTextContainer" > 
                    <img className="HomeImage" loading="lazy" src={sunset} alt="SloganImage" /> 
                    <p className="homeoverlay-text high">{t('Home.2')}</p>
                    </div>
                    </LazyLoad>
                    <LazyLoad>
                    <div className="imageTextContainer" >
                    <img className="HomeImage" loading="lazy" src={energy} alt="SloganImage" /> 
                    <p className="homeoverlay-text mid">{t('Home.3')}</p>
                    </div>
                    </LazyLoad>
                
                
        
            </div>
        </motion.div>
            


        
        
       
    );
    
}

export default Home