import {useState} from "react";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {useRef} from "react";
import {FaBars, FaTimes } from "react-icons/fa";
import React from 'react';
import "../styles/main.css";
import wheat from '../assets/images/wheat.jpg';
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function Maps() {
    const { t, i18 } = useTranslation();
    const animation = useAnimation();
    const [ref, inView, entry] = useInView({
    triggerOnce: false,
    });
    const aboutVariant = {
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0.3 },
    };
    useEffect(() => {
        if (inView) {
          animation.start("visible");
        } else {
            animation.start("hidden");
        }
      }, [animation, inView]);
    return (
        <motion.div
            id="maps"
            className="aboutContainer"
            ref={ref}
            initial="hidden"
            animate={animation}
            variants={aboutVariant}
            >
            <p id="maps" className="mapsContainer">
            <p className="mapText">
                    <p className="mapText Bold" dangerouslySetInnerHTML={{ __html: t('ContactUs.0') }}></p>
                    <hr/>
                    <p>{t('Address.0')}</p>
                    <hr />
                    <p dangerouslySetInnerHTML={{ __html: t('ContactUs.2') }}></p>
                    
    
                </p>
                
                <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d922.7873051630775!2d114.18775551679965!3d22.310195636179106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400df2215b55b%3A0xdee1933d95c202e7!2sphase%203%2C%209-11A%20Hok%20Yuen%20St%2C%20Hung%20Hom%2C%20Hong%20Kong!5e0!3m2!1sen!2sca!4v1696386649503!5m2!1sen!2sca"
                    width="600"
                    height="450"
                    style={{border:"0"}}
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                
            </p>
        </motion.div>
    );
}